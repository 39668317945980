<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata" :inline="true" :model="formdata.saveJson" class="demo-form-inline form_row" size="small" label-width="200px">
                <!-- <el-form-item label="监测点" prop="JCDMC">
                    <el-input v-model="formdata.saveJson.JCDMC" :maxlength="50" placeholder="请输入监测点" :disabled="!isEdit"></el-input>
                </el-form-item> -->
                <el-form-item label="预警时间" prop="FBSJ">
                    <el-date-picker v-model="formdata.saveJson.FBSJ" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :disabled="!isEdit" placeholder="请选择预警时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="级别" prop="YJDJ">
                    <el-input v-model="formdata.saveJson.YJDJ" placeholder="请输入级别" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="类型" prop="YJLX">
                    <el-input v-model="formdata.saveJson.YJLX" placeholder="请输入类型" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="说明" prop="YJXHMS">
                    <el-input v-model="formdata.saveJson.YJXHMS" placeholder="请输入说明" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="是否解除" prop="SFJCYJ">
                    <el-input v-model="formdata.saveJson.SFJCYJ" placeholder="请输入是否解除" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit">
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from '../../../edit-button.js';
import infoMixin from '../../../info_mixin.js';
import { mapActions } from 'vuex';
export default {
    name: "equipmentdata_meteorologicaldisaster_form",
    components: {
    },
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: '',
            formdata: {
                saveJson: {
                    FBSJ: '',
                    YJDJ: '',
                    YJLX: '',
                    YJXHMS: '',
                    SFJCYJ: '',
                },
                heritageId: '',
                itemID: '8020512',
                userName: '',
                userId: ''
            },
            itemID: '8020512',
        };
    },
    computed: {
    },
    watch: {
        'formdata.saveJson.cnsxlylx': function() {
        },
    },
    mounted() {
    },
    methods: {
        ...mapActions([
            'GetDetailsData',
        ]),
        // 获取详情
        async getFromData() {
            let params = {
                itemid: this.itemID,
                ID: this.$route.query.id,
            };
            let result = await this.GetDetailsData(params);
            if (result && result.length > 0) {
                let res = result[0];
                // res.FBSJ = res.YJSJ;
                // res.YJDJ = res.JB;
                // res.YJLX = res.LX;
                // res.YJXHMS = res.SM;
                // res.SFJCYJ = res.SFJC;
                Object.assign(this.formdata.saveJson, res);
            }
        },
    }
};
</script>